import React from 'react';
import Button from './Button';

import hybridLogoBlack from '../assets/images/hybrid-logo-black.svg';

class Navbar extends React.Component {
  handleClick = () => {
    const element = document.getElementById('joinWaitlist');
    element.scrollIntoView();
  };

  render() {
    return (
      <div id="navbar">
        <div className="navbar-brand">
          <a href="/">
            <img src={hybridLogoBlack} alt="hybrid logo" width="80" />
          </a>
        </div>
        <div className="navbar-button">
          <Button size="small" onClick={this.handleClick}>GET STARTED</Button>
        </div>
      </div>
    );
  }
}
export default Navbar;
