import React from 'react';

import Button from './Button';
import Input from './Input';
import AnimatedSection from './AnimatedSection';

import waitlistImg from '../assets/images/waitlist.png';

class Waitlist extends React.Component {
  handleInput = e => {
    const { handleInput } = this.props;
    handleInput(e);
  };

  handleSubmit = () => {
    const { handleSubmit } = this.props;
    handleSubmit();
  };

  render() {
    const { value } = this.props;
    return (
      <AnimatedSection>
        <div className="waitlist-section">
          <div className="waitlist-section-image">
            <img src={waitlistImg} alt="waitlist" />
          </div>
          <div className="waitlist-section-right">
            <div className="waitlist-section-heading">
              Want early access to our other offers?
            </div>
            <div className="waitlist-section-subheading">
              Finance at every step. From application fees, health insurance, to visa
              processing, etc. You only get charged on what you use!
            </div>
            <div className="waitlist-section-email-button">
              <Input placeholder="Input Email" onChange={this.handleInput} type="email" value={value} autocomplete="email" name="email" />
              <Button background="dark" size="large" onClick={this.handleSubmit}>JOIN OUR WAITLIST</Button>
            </div>
          </div>
        </div>
      </AnimatedSection>
    );
  }
}
export default Waitlist;
