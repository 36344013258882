import React from 'react';

import AnimatedSection from './AnimatedSection';

import hybridLogoBlack from '../assets/images/hybrid-logo-black.svg';

const Footer = () => (
  <AnimatedSection>
    <section id="footer-section">
      <div className="footer-logo">
        <a href="/">
          <img src={hybridLogoBlack} alt="logo" width="90" />
        </a>
      </div>

      <div className="footer-text">
        <div className="footer-text-info">
          Hybrid is a digital lending platform powered by Carrot.
          Please note that joining the waitlist does not guarantee the opportunity to be invited.
          Thank you for your patience and understanding.
        </div>
        <div className="footer-text-terms">
          <a href={`https://${process.env.NODE_ENV !== 'production' ? 'staging' : 'www'}.carrotcredit.com/user-agreement`}>User Agreement</a>
          <a href={`https://${process.env.NODE_ENV !== 'production' ? 'staging' : 'www'}.carrotcredit.com/privacy-policy`}>Privacy Policy</a>
        </div>
      </div>
    </section>
  </AnimatedSection>
);
export default Footer;
