import {
  ERROR,
  LOADING,
  CONTACT
} from '../actions';

export const initialState = {
  loading: [],
};

export default function contact(state = initialState, action) {
  const { loading } = state;
  const { payload } = action;
  switch (action.type) {
  case LOADING:
    return {
      ...state,
      loading: loading.some(item => item === payload)
        ? loading.filter(item => item !== payload)
        : [...loading, payload],
    };

  case ERROR:
    return state;

  case CONTACT:
    return {
      ...state,
    };

  default:
    return state;
  }
}

