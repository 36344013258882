import React from 'react';

import Button from './Button';

class TopSection extends React.Component {
  handleClick = () => {
    const { buttonLink } = this.props;
    if (buttonLink) {
      window.open(buttonLink, '_blank');
    } else {
      const element = document.getElementById('joinWaitlist');
      element.scrollIntoView();
    }
  };

  render() {
    const {
      heading, subheading, background, subheadingExtra, buttonText,
    } = this.props;
    return (
      <div id="top-section" className={background}>
        <div className="top-section-content">
          {heading && (
          <div className="top-section-heading">
            {heading}
          </div>
          )}
          {subheading && (
          <div className="top-section-subheading">
            {subheading}
          </div>
          )}
          {subheadingExtra && (
          <div className="top-section-subheading">
            {subheadingExtra}
          </div>
          )}
          <div className="top-section-cta">
            <Button background="dark" onClick={this.handleClick}>{buttonText || 'USE HYBRID'}</Button>
          </div>
        </div>
      </div>
    );
  }
}
export default TopSection;
