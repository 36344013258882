import React, { Component } from 'react';
import PropTypes from 'prop-types';

import longArrowRightImg from '../assets/images/long-arrow-right.svg';

class Button extends Component {
  render() {
    const {
      children, onClick, disabled, loading, background, size, arrow,
    } = this.props;

    return (
      <button
        disabled={loading || disabled}
        className={`button ${arrow ? 'arrow' : ''} ${size} ${background || ''}`}
        onClick={onClick}
      >
        {children}
        {arrow ? <img className="button-arrow" src={longArrowRightImg} alt="arrow" /> : ''}
      </button>
    );
  }
}

Button.defaultProps = {
  disabled: false,
};

Button.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['large', 'small', '']),
};

export default Button;
