import * as Helpers from '../../helpers';

export const CONTACT = 'CONTACT';
export const ERROR = 'ERROR';
export const LOADING = 'LOADING';

export const contact = (email, target, success, error) => Helpers.api(
  '/admin/contact',
  'POST',
  {
    email,
    target,
  },
  { error, success },
  { error: ERROR, loading: LOADING, responder: CONTACT }
);
