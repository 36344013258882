import React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import Routes from './routes';

if (process.env.REACT_APP_NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://2e454b1e87a147faaa6578872e6271e3@o605468.ingest.sentry.io/5923744',
    environment: process.env.REACT_APP_NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

class Carrot extends React.Component {
  render() {
    return (
      <Routes />
    );
  }
}

export default Carrot;
