import React, { useEffect, useState, useRef } from 'react';

const AnimatedSection = props => {
  const {
    children, id, className, variant,
  } = props;
  const myRef = useRef();
  const [isVisible, setVisible] = useState(false);
  useEffect(() => {
    let observerRefValue = null;
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setVisible(true);
        observer.unobserve(myRef.current);
      }
    });
    observerRefValue = myRef.current;
    observer.observe(observerRefValue);
    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue);
    };
  }, []);

  return (
    <div id={id} className={className}>
      <div ref={myRef} className={`${variant || 'fade-in-section'} ${isVisible ? 'is-visible' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default AnimatedSection;
