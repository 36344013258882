import Home from './Home';

const baseRoutes = {
  private: [],
  public: [
    {
      component: Home,
      exact: true,
      path: '/',
    },
  ],
};

export default baseRoutes;
