import React from 'react';

import Button from './Button';
import AnimatedSection from './AnimatedSection';

import surveyImg from '../assets/images/survey.png';

class Survey extends React.Component {
  handleClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLScSapxj_dusjbaRr4z_QkTAOVHL0OWMJnYqk09tMO9v9kHrCg/viewform?usp=sf_link', '_blank');
  };

  render() {
    return (
      <AnimatedSection>
        <div className="survey-section">
          <div className="survey-section-image">
            <img src={surveyImg} alt="waitlist" />
          </div>
          <div className="survey-section-right">
            <div className="survey-section-heading">
              We are always looking for new ways to serve you.
            </div>
            <div className="survey-section-subheading">
              Help us figure out how.
            </div>
            <div className="survey-section-button">
              <Button background="dark" size="large" onClick={this.handleClick}>TAKE A QUICK SURVEY</Button>
            </div>
          </div>
        </div>
      </AnimatedSection>
    );
  }
}

export default Survey;
