import React from 'react';
import PropTypes from 'prop-types';

class Input extends React.Component {
  onChange = e => {
    const { onChange } = this.props;
    onChange(e);
  };

  render() {
    const {
      onChange, type, value, placeholder, disabled, autocomplete, required, name,
    } = this.props;
    return (
      <div id="input-div">
        <input
          disabled={disabled}
          className="input"
          onChange={onChange}
          type={type}
          value={value}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          autoComplete={autocomplete}
          required={required}
          placeholder={placeholder}
          name={name}
        />
      </div>
    );
  }
}

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.node,
  type: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

export default Input;
