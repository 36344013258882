import React, { Component } from 'react';
import AnimatedSection from './AnimatedSection';

class Join extends Component {
  render() {
    return (
      <AnimatedSection>
        <div id="joinWaitlist" className="join-waitlist">
          <div className="join-waitlist-text">
            Join our waitlist
          </div>
          <div className="join-waitlist-line" />
        </div>
      </AnimatedSection>
    );
  }
}

export default Join;
